<template>
  <div class="h-full w-full grid-cols-1" v-if="Object.values(isDataReady).every(v => v === true)">
    <div class="flex h-full w-full flex-col space-y-4">
      <div
        class="flex h-full w-full items-center justify-center space-x-2 rounded border border-gray-800 bg-gray-800/25"
        v-if="!alertConfig.metrics[0].id && alertCoins.length"
      >
        <ExclamationCircleIcon class="h-4 w-4 text-gray-400" />
        <span class="block text-xs">Select a Metric to Visualize</span>
      </div>
      <div
        class="h-full"
        v-else-if="alertCoins.length == 1 && uniqueMetricsSeries?.length && selectedChartSeries?.series?.length"
      >
        <AlertsCustomVisualizationChart
          v-if="selectedChartSeries?.series && selectedChartSeries?.series?.length && uniqueMetricsSeries?.length"
          class="h-full"
          :series="selectedChartSeries"
          :alert-coins="alertCoins"
          :alert-data="alertConfig"
          :metrics="uniqueMetricsSeries"
          :selected-timeframe="selectedTimeframe"
        />
      </div>
      <div
        v-if="
          alertCoins.length == 1 &&
          selectedChartSeries.series &&
          uniqueMetricsSeries.length &&
          selectedChartSeries.series.length
        "
        class="flex justify-end"
      >
        <BaseRadio v-model="selectedTimeframe" :options="timeframes.map(t => t.id)" />
      </div>
      <div
        v-if="alertCoins.length"
        class="rounded border border-gray-800"
        :class="alertCoins.length == 1 ? 'h-32' : 'h-full'"
      >
        <AlertsCustomVisualizationTable :alert-data="alertConfig" :alert-coins="alertCoins" />
      </div>
      <div class="flex h-full w-full items-center justify-center space-x-2" v-else>
        <ExclamationCircleIcon class="h-4 w-4 text-gray-400" />
        <span class="block text-xs">Select a Coin to Visualize</span>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col pr-8">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
</template>
<script setup>
import { ref, watch, onMounted, inject, computed } from 'vue';
import { useStore } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import { DateTime } from 'luxon';
import { multiColorSeries } from '@/components/chart/utils/ChartBaseTheming';
import { timeframes } from '@/components/alerts/custom/visualization/AlertsCustomVisualizationTimeframes.js';
import AlertsCustomVisualizationChart from '@/components/alerts/custom/visualization/AlertsCustomVisualizationChart.vue';
import AlertsCustomVisualizationTable from '@/components/alerts/custom/visualization/AlertsCustomVisualizationTable.vue';
import { useWatchlistStore } from '@/stores/watchlists';
import useEmitter from '@/composeables/emitter';

const emitter = useEmitter();
const $http = inject('http');
const store = useStore();
const $watchlistStore = useWatchlistStore();

const isDataReady = ref({ table: false });

const selectedRow = ref(null);

const selectChanged = row => {
  selectedRow.value = row;
};

const non_historical_metrics = ref([
  'change_1d',
  'change_30d',
  'change_7d',
  'early_holder_balance_1d_change_pct_ethereum',
  'early_holder_balance_7d_change_pct_ethereum',
  'early_holder_balance_30d_change_pct_ethereum',
  'whale_balance_1d_change_pct_ethereum',
  'whale_balance_30d_change_pct_ethereum',
  'whale_balance_7d_change_pct_ethereum',
  'early_holder_balance_1d_change_pct_bitcoin',
  'early_holder_balance_7d_change_pct_bitcoin',
  'early_holder_balance_30d_change_pct_bitcoin',
  'whale_balance_1d_change_pct_bitcoin',
  'whale_balance_30d_change_pct_bitcoin',
  'whale_balance_7d_change_pct_bitcoin',
  'whale_balance_1d_change_pct_agg',
  'whale_balance_30d_change_pct_agg',
  'whale_balance_7d_change_pct_agg',
  'expected_shortfall_95_pct',
  'expected_shortfall_99_pct',
  'value_at_risk_95_pct',
  'value_at_risk_99_pct',
  'fourchan_24h_posts_change_pct',
  'price_return_btc_180_days',
  'price_return_btc_365_days',
  'price_return_btc_4_hours',
  'price_return_btc_90_days',
  'month_to_date_return_btc',
  'quarter_to_date_return_btc',
  'year_to_date_return_btc',
  'price_return_eth_180_days',
  'price_return_eth_1_hour',
  'price_return_eth_24_hours',
  'price_return_eth_30_days',
  'price_return_eth_365_days',
  'price_return_eth_4_hours',
  'price_return_eth_7_days',
  'price_return_eth_90_days',
  'month_to_date_return_eth',
  'quarter_to_date_return_eth',
  'year_to_date_return_eth',
  'price_return_180_days',
  'price_return_365_days',
  'price_return_4_hours',
  'price_return_90_days',
  'month_to_date',
  'price_eth',
  'quarter_to_date',
  'sharp_ratio',
  'year_to_date',
  'value_at_risk_99_pct',
  'value_at_risk_95_pct',
  'expected_shortfall_99_pct',
  'expected_shortfall_95_pct',
  'youtube_24h_views_change_pct',
  'commit_count_change_30d',
  'active_users_change_30d',
  'tvl_mcap',
  'pct_circulation_supply',
  'market_cap_rank_24h',
  // -- Exchange FLow Metrics start
  '1h_exchange_flow_supply_pct_avalanche',
  '24h_exchange_flow_supply_pct_avalanche',
  '1h_exchange_flow_usd_avalanche',
  '24h_exchange_flow_usd_avalanche',
  'total_supply_held_pct_avalanche',
  '1h_exchange_flow_supply_pct_ethereum',
  '24h_exchange_flow_supply_pct_ethereum',
  '1h_exchange_flow_usd_ethereum',
  '24h_exchange_flow_usd_ethereum',
  'total_supply_held_pct_ethereum',
  '1h_exchange_flow_supply_pct_bitcoin',
  '24h_exchange_flow_supply_pct_bitcoin',
  '1h_exchange_flow_usd_bitcoin',
  '24h_exchange_flow_usd_bitcoin',
  'total_supply_held_pct_bitcoin',
  '1h_exchange_flow_supply_pct_agg',
  '24h_exchange_flow_supply_pct_agg',
  '1h_exchange_flow_usd_agg',
  '24h_exchange_flow_usd_agg',
  'total_supply_held_pct_agg',
  '1h_exchange_flow_supply_pct_polygon',
  '24h_exchange_flow_supply_pct_polygon',
  '1h_exchange_flow_usd_polygon',
  '24h_exchange_flow_usd_polygon',
  'total_supply_held_pct_polygon',
  '1h_exchange_flow_supply_pct_tezos',
  '24h_exchange_flow_supply_pct_tezos',
  '1h_exchange_flow_usd_tezos',
  '24h_exchange_flow_usd_tezos',
  'total_supply_held_pct_tezos',
  '1h_exchange_flow_supply_pct_hedera',
  '24h_exchange_flow_supply_pct_hedera',
  '1h_exchange_flow_usd_hedera',
  '24h_exchange_flow_usd_hedera',
  'total_supply_held_pct_hedera',
  'funding',
  'open_interest',
  'next_fr',
  'yield',
  'oi_change_1h',
  'oi_change_24h',
  'liquidations_long',
  'liquidations_short'
  // -- end
]);

const developmentColumns = ref(['commit_count', 'active_developer', 'active_users_30d']);
const matchKeysForOnchainColumns = ref({
  // avalanche
  num_txs_24h_avalanche: 'num_txs_24hr_avalanche',
  num_tx_30d_avg_avalanche: 'num_txs_30d_avg_avalanche',
  num_active_addrs_24h_avalanche: 'num_active_addrs_24hr_avalanche',
  num_active_addrs_30d_avg_avalanche: 'num_active_addrs_30d_avg_avalanche',
  num_active_addrs_24h_relative_change_avalanche: 'num_active_addrs_24hr_rel_change_pct_avalanche',
  num_tx_24h_relative_change_avalanche: 'num_txs_24hr_rel_change_pct_avalanche',

  // ethereum
  num_txs_24h_ethereum: 'num_txs_24hr_ethereum',
  num_tx_30d_avg_ethereum: 'num_txs_30d_avg_ethereum',
  num_active_addrs_24h_ethereum: 'num_active_addrs_24hr_ethereum',
  num_active_addrs_30d_avg_ethereum: 'num_active_addrs_30d_avg_ethereum',
  num_active_addrs_24h_relative_change_ethereum: 'num_active_addrs_24hr_rel_change_pct_ethereum',
  num_tx_24h_relative_change_ethereum: 'num_txs_24hr_rel_change_pct_ethereum',
  // polygon
  num_txs_24h_polygon: 'num_txs_24hr_polygon',
  num_tx_30d_avg_polygon: 'num_txs_30d_avg_polygon',
  num_active_addrs_24h_polygon: 'num_active_addrs_24hr_polygon',
  num_active_addrs_30d_avg_polygon: 'num_active_addrs_30d_avg_polygon',
  num_active_addrs_24h_relative_change_polygon: 'num_active_addrs_24hr_rel_change_pct_polygon',
  num_tx_24h_relative_change_polygon: 'num_txs_24hr_rel_change_pct_polygon',

  // tezos
  num_txs_24h_tezos: 'num_txs_24hr_tezos',
  num_tx_30d_avg_tezos: 'num_txs_30d_avg_tezos',
  num_active_addrs_24h_tezos: 'num_active_addrs_24hr_tezos',
  num_active_addrs_30d_avg_tezos: 'num_active_addrs_30d_avg_tezos',
  num_active_addrs_24h_relative_change_tezos: 'num_active_addrs_24hr_rel_change_pct_tezos',
  num_tx_24h_relative_change_tezos: 'num_txs_24hr_rel_change_pct_tezos',

  // hedera
  num_txs_24h_hedera: 'num_txs_24hr_hedera',
  num_tx_30d_avg_hedera: 'num_txs_30d_avg_hedera',
  num_active_addrs_24h_hedera: 'num_active_addrs_24hr_hedera',
  num_active_addrs_30d_avg_hedera: 'num_active_addrs_30d_avg_hedera',
  num_active_addrs_24h_relative_change_hedera: 'num_active_addrs_24hr_rel_change_pct_hedera',
  num_tx_24h_relative_change_hedera: 'num_txs_24hr_rel_change_pct_hedera',

  // bitcoin
  num_txs_24h_bitcoin: 'num_txs_24hr_bitcoin',
  num_tx_30d_avg_bitcoin: 'num_txs_30d_avg_bitcoin',
  num_active_addrs_24h_bitcoin: 'num_active_addrs_24hr_bitcoin',
  num_active_addrs_30d_avg_bitcoin: 'num_active_addrs_30d_avg_bitcoin',
  num_active_addrs_24h_relative_change_bitcoin: 'num_active_addrs_24hr_rel_change_pct_bitcoin',
  num_tx_24h_relative_change_bitcoin: 'num_txs_24hr_rel_change_pct_bitcoin',

  // aggregated chains
  num_txs_24h_agg: 'num_txs_24hr_agg',
  num_tx_30d_avg_agg: 'num_txs_30d_avg_agg',
  num_active_addrs_24h_agg: 'num_active_addrs_24hr_agg',
  num_active_addrs_30d_avg_agg: 'num_active_addrs_30d_avg_agg',
  num_active_addrs_24h_relative_change_agg: 'num_active_addrs_24hr_rel_change_pct_agg',
  num_tx_24h_relative_change_agg: 'num_txs_24hr_rel_change_pct_agg'
});
const stakingColumns = ref([
  'active_validators',
  'annualized_rewards',
  'annualized_rewards_usd',
  'average_validator_balance',
  'benchmark_commission',
  'benchmark_daily_fees',
  'block_height',
  'block_time',
  'block_time_30d',
  'consensus_apr',
  'current_slot',
  'eligible_supply',
  'execution_apr',
  'fee_revenue',
  'inactive_validators',
  'inflation_rate',
  'lido_operator_count',
  'net_staking_flow',
  'net_staking_flow_24h',
  'net_staking_flow_30d',
  'net_staking_flow_365d',
  'net_staking_flow_7d',
  'real_reward_rate',
  'reward_rate',
  'r_r_ratio',
  'expected_solo_staking_reward_rate',
  'staked_tokens',
  'staked_tokens_trend_24h',
  'stakewise_operator_count',
  'staking_marketcap',
  'staking_ratio',
  'staking_roi_365d',
  'total_roi_365d',
  'total_staking_wallets',
  'total_validators',
  'withdrawal_queue'
]);
const selectedTimeframe = ref('6M');

const alertData = ref(
  JSON.parse(window.localStorage.getItem(constructLocalStorageName('alertData'))) || {
    coins: { coins: [] },
    watchlist: { id: null },
    category: { id: null },
    subcategory: { id: null },
    metrics: [
      { mainMetric: 'all', id: null, name: null, comparison: 'greater_than', value: null, type: null, required: false }
    ]
  }
);

function constructLocalStorageName(s) {
  let path = store.state.path
    .split('.')
    .map((s, i) => {
      return i == 0 ? s : s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join('');
  s = s.charAt(0).toUpperCase() + s.slice(1);
  return `${path}${s}`;
}

const props = defineProps({
  alertVal: {
    type: Object,
    default: null
  }
});

const alertConfig = ref({});

watch(
  () => alertConfig.value,
  async (newVal, oldVal) => {
    getAlertCoins().then(() => {
      formatCoinsSeries();
    });
  }
);

watch(selectedTimeframe, () => {
  formatCoinsSeries();
});

const allCategories = ref({});

const setCategoriesWithSubcategories = async () => {
  const resp = await $http.get('/data/coin_categories_subnav');
  const data = resp.data;
  (data.categories || [])
    .filter(cat => cat.id !== 0)
    .forEach(cat => {
      allCategories.value[cat.id.toLocaleString()] = cat.subpages.map(s => s.id).filter(s => s);
    });
};

const alertCoins = ref([]);

const getAlertCoins = async () => {
  isDataReady.value.table = false;
  if (alertConfig.value.watchlist) {
    const selectedWatchlist = await getWatchlist(alertConfig.value.watchlist.id);
    alertCoins.value = selectedWatchlist.entities_uids;
  } else if (alertConfig.value.category) {
    const matchingCoins = [];
    if (Object.keys(allCategories.value).length == 0) {
      await setCategoriesWithSubcategories();
    }
    allCategories.value[alertConfig.value.category.id].forEach(subcategory => {
      matchingCoins.push(coins.value.filter(coin => coin.category_ids.includes(subcategory)));
    });
    const flatCoins = matchingCoins
      .flat()
      .sort((a, b) => b.market_cap - a.market_cap)
      .map(coin => coin.coin_uid);
    alertCoins.value = [...new Set(flatCoins)];
  } else if (alertConfig.value.subcategory) {
    alertCoins.value = coins.value
      .filter(coin => coin.category_ids.includes(alertConfig.value.subcategory.id))
      .map(coin => coin.coin_uid);
  } else if (alertConfig.value.coins) {
    alertCoins.value = alertConfig.value.coins;
  } else {
    alertCoins.value = [];
  }
  if (alertCoins.value.length == 1) {
    selectedRow.value = coins.value.find(c => c.uid == alertCoins.value[0]);
  } else {
    selectedRow.value = {};
  }
  isDataReady.value.table = true;
};

const selectedChartSeries = computed(() => {
  const metrics = uniqueMetricsSeries.value;
  let selectedCoinData = [];
  let selectedSeriesData = [];
  let chartData = {};

  if (metrics.length && selectedRow.value) {
    selectedCoinData = allChartSeries.value;
    if (selectedCoinData?.length) {
      Object.keys(selectedCoinData[0]).forEach(key => {
        if (!['id', 'datetime'].includes(key)) {
          const metric = metrics.find(e => e.id == key);
          const seriesIndex = metrics.findIndex(e => e.id == metric?.id);
          if (seriesIndex >= 0) {
            selectedSeriesData = selectedSeriesData.concat(
              addSeriesInChart(metric, selectedCoinData, seriesIndex, key)
            );
          }
        }
      });
    }
    let metricTvl = metrics.find(e => e.id == 'tvl');
    if (tvlData.value?.length && metricTvl) {
      let seriesIndex = metrics.findIndex(e => e.id == metricTvl?.id);
      selectedSeriesData = selectedSeriesData.concat(addSeriesInChart(metricTvl, tvlData.value, seriesIndex, 'tvl'));
    }
    let developmentMetric = metrics.find(x => developmentColumns.value.includes(x.id));
    if (developmentData.value?.length && developmentMetric) {
      let seriesIndex = metrics.findIndex(e => e.id == developmentMetric?.id);

      selectedSeriesData = selectedSeriesData.concat(
        addSeriesInChart(developmentMetric, developmentData.value, seriesIndex, developmentMetric?.id)
      );
    }
    let stakingMetric = metrics.find(x => stakingColumns.value.includes(x.id));
    if (stakingData.value && stakingMetric) {
      metrics
        .filter(x => stakingColumns.value.includes(x.id))
        .forEach(metric => {
          let seriesIndex = metrics.findIndex(e => e.id == metric.id);
          selectedSeriesData = selectedSeriesData.concat(
            addSeriesInChart(metric, Object.values(stakingData.value), seriesIndex, metric.id)
          );
        });
    }
    let onchainMetric = metrics.find(x => Object.values(matchKeysForOnchainColumns.value).includes(x.id));
    if (onChainData.value?.length && onchainMetric) {
      let filteredMetrics = metrics.filter(ele => Object.values(matchKeysForOnchainColumns.value).includes(ele.id));

      filteredMetrics.map(fm => {
        let seriesIndex = metrics.findIndex(e => e.id == fm?.id);
        let associatedKey = '';
        Object.entries(matchKeysForOnchainColumns.value).forEach(([key, value]) => {
          if (value == fm?.id) associatedKey = key;
        });
        selectedSeriesData = selectedSeriesData.concat({
          name: fm?.name,
          formatType: fm?.type,
          metric_id: fm.id,
          yAxis: seriesIndex,
          color: multiColorSeries(seriesIndex),
          data: onChainData.value.map(e => {
            return [
              DateTime.fromFormat(e.datetime, 'yyyy-MM-dd HH:mm:ss').toUnixInteger() * 1000,
              fm.type?.includes('percent')
                ? e[associatedKey]
                  ? Number(e[associatedKey]) * 100
                  : null
                : e[associatedKey]
                ? Number(e[associatedKey])
                : null,
              associatedKey
            ];
          })
        });
      });
    }
    chartData = {
      coin: selectedRow.value.coin.name,
      uid: selectedRow.value.uid,
      series: selectedSeriesData
    };
  }

  return chartData;
});

function addSeriesInChart(metric, data, seriesIndex, key) {
  return {
    name: metric?.name,
    formatType: metric?.type,
    metric_id: key,
    yAxis: seriesIndex,
    color: multiColorSeries(seriesIndex),
    data:
      key == 'tvl'
        ? data
        : data.map(e => {
            return [
              (stakingColumns.value.includes(metric.id)
                ? DateTime.fromFormat(e.datetime, 'yyyy-MM-dd HH:mm:ss').toUnixInteger()
                : DateTime.fromISO(e.datetime).toUnixInteger()) * 1000,
              metric.type?.includes('percent') ? (e[key] ? Number(e[key]) * 100 : null) : e[key] ? Number(e[key]) : null
            ];
          })
  };
}

const getWatchlist = async watchlistId => {
  return await $watchlistStore.getWatchlist(watchlistId);
};

const allChartSeries = ref({});

const coins = computed(() => store.getters.coins);

function getUniqueListBy(arr, key) {
  return [...new Map(arr?.map(item => [item[key], item])).values()];
}

const uniqueMetricsSeries = computed(() => {
  return getUniqueListBy(
    alertConfig.value?.metrics?.filter(metric => metric.id != null),
    'id'
  );
});

const convertDate = date => {
  return `${date.getUTCFullYear().toString().padStart(4, '0')}-${(date.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')} ${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
};
async function getCoinHistory(coin, items) {
  const date = new Date();
  const timeframe = timeframes.find(e => e.id == selectedTimeframe.value);
  const res = await $http.post('/data/coins_historical_grouped', {
    coin_uids: [coin],
    start_datetime: new Date(date.getTime() - timeframe.time).toISOString(),
    end_datetime: date.toISOString(),
    items: items,
    frequency: timeframe.frequency
  });
  return res?.data?.data;
}

async function fetchHistoricalStats(coin, type) {
  const date = new Date();
  const timeframe = timeframes.find(e => e.id == selectedTimeframe.value);

  const params = {
    coin_uids: [coin],
    start_datetime: new Date(date.getTime() - timeframe.time).toISOString(),
    end_datetime: date.toISOString(),
    frequency: timeframe.frequency,
    type: type,
    page: 'alerts'
  };

  if (coinHistoryCache[JSON.stringify(params)]) {
    return coinHistoryCache[JSON.stringify(params)];
  }
  const res = await $http.post(`/data/historical_stats/${type}_historical`, params);
  coinHistoryCache[JSON.stringify(params)] = res?.data;
  return res?.data;
}

async function getCoinTvlHistory(coin) {
  const date = new Date();
  const timeframe = timeframes.find(e => e.id == selectedTimeframe.value);
  try {
    const response = await $http.get('/data/coin_protocol_info', {
      params: { coin_uid: coin }
    });
    if (!response || !response.data) {
      return;
    }
    const { data } = response;
    const days = Math.ceil(
      (date.toISOString() - new Date(date.getTime() - timeframe.time).toISOString()) / (1000 * 60 * 60 * 24)
    );
    return data
      .map(point => {
        return [
          Math.round(point.date * 1000 + (DateTime.local().offset / 60) * 60 * 60 * 1000),
          point.totalLiquidityUSD
        ];
      })
      .slice(data.length - days, data.length);
  } catch {
    return;
  }
}

const tvlData = ref(null);
const developmentData = ref(null);
const onChainData = ref(null);
const stakingData = ref(null);
const availableStakingMetrics = ref([]);
const coinHistoryCache = {};

async function formatCoinsSeries() {
  const metrics = uniqueMetricsSeries.value;
  let cols = [
    non_historical_metrics.value,
    developmentColumns.value,
    stakingColumns.value,
    Object.values(matchKeysForOnchainColumns.value)
  ].flat();

  let groupHistoricalFilteredMetrics = metrics
    .filter(metric => {
      return !cols.includes(metric?.id);
    })
    .map(e => e.id)
    .join(',');

  if (groupHistoricalFilteredMetrics?.length > 0 && selectedRow.value?.uid) {
    await getCoinHistory(selectedRow.value.uid, groupHistoricalFilteredMetrics).then(e => {
      allChartSeries.value = e;
    });
  }
  if (metrics.find(x => x.id == 'tvl') && selectedRow.value?.uid && !tvlData.value) {
    let triggered = tvlData.value && selectedTimeframe.value == '1Y' ? true : !tvlData.value ? true : false;
    if (triggered) {
      await getCoinTvlHistory(selectedRow.value.uid).then(e => {
        tvlData.value = e;
      });
    }
  }
  if (metrics.find(x => developmentColumns.value.includes(x.id)) && selectedRow.value.uid) {
    let triggered =
      developmentData.value && selectedTimeframe.value == '1Y' ? true : !developmentData.value ? true : false;
    if (triggered) {
      await fetchHistoricalStats(selectedRow.value.uid, 'development').then(e => {
        developmentData.value = e;
      });
    }
  }
  if (metrics.find(x => Object.values(matchKeysForOnchainColumns.value).includes(x.id)) && selectedRow.value.uid) {
    let triggered = onChainData.value && selectedTimeframe.value == '1Y' ? true : !onChainData.value ? true : false;
    let response;
    if (triggered) {
      await fetchHistoricalStats(selectedRow.value.uid, 'onchain').then(e => {
        response = e;
      });

      // Create new object with datetime key as property and values as arrays of objects with same datetime
      const combined = Object.values(response)
        ?.flat()
        .reduce((acc, curr) => {
          if (acc[curr.datetime]) {
            acc[curr.datetime].push(curr);
          } else {
            acc[curr.datetime] = [curr];
          }
          return acc;
        }, {});

      // Iterate through combined object and combine arrays of objects with same datetime key

      let combinedData = Object.values(combined).map(arr => {
        return arr.reduce(
          (acc, curr) => {
            Object.entries(curr).forEach(([key, value]) => {
              if (key !== 'datetime') {
                acc[key] = acc[key]
                  ? parseFloat(acc[key]) + parseFloat(value)
                  : parseFloat(value)
                  ? parseFloat(value)
                  : 0;
              }
            });
            return acc;
          },
          { datetime: arr[0].datetime }
        );
      });

      onChainData.value = combinedData?.sort(
        (a, b) => Number(new Date(a.datetime).getTime()) - Number(new Date(b.datetime).getTime())
      );
    }
  }
  if (metrics.find(x => stakingColumns.value.includes(x.id)) && selectedRow.value.uid) {
    const stakingMetrics = metrics
      .filter(x => stakingColumns.value.includes(x.id) && !availableStakingMetrics.value.includes(x.id))
      .map(x => x.id);
    if (stakingMetrics.length) {
      await fetchStakingHistoricalData(selectedRow.value.uid, stakingMetrics).then(e => {
        if (!stakingData.value) stakingData.value = {};
        e.forEach(i => {
          stakingData.value[i.datetime] = { ...(stakingData.value[i.datetime] || {}), ...i };
        });
      });
    }
  }
  isDataReady.value.coins = true;
}

async function fetchStakingHistoricalData(coin_uid, metrics) {
  availableStakingMetrics.value = [...availableStakingMetrics.value, ...metrics];
  const params = {
    coin_uids: coin_uid,
    metric_keys: metrics.join(',')
  };

  let res = await $http.post(`/data/historical_stats/staking_historical`, params);
  return res?.data;
}

function formatConfigData(data) {
  const self = this;
  let config = {};
  for (const [k, v] of Object.entries(data)) {
    if (['watchlist', 'category', 'subcategory'].includes(k)) {
      if (v && (v.uid != null || v.id != null)) {
        config[k] = v;
      }
    } else if ('coins' == k) {
      if (v && v.length > 0) {
        config[k] = v;
      }
    } else {
      config[k] = v;
    }
  }
  return config;
}

onMounted(() => {
  emitter.$on('config:alert', data => {
    alertConfig.value = data.config;
  });
  if (isEmpty(alertConfig.value)) {
    alertConfig.value = formatConfigData(alertData.value);
  }
});
</script>
